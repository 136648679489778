<template>
	<div>
		<b-row>
			<b-colxx xxs="12">				

				<b-card class="mb-4" :title="$t('Planes de suscripción')">

					<button variant="primary" class="btn mt-4 btn-outline-success" data-toggle="modal" data-target=".addPlanModal">Agregar nuevo</button>

					<div class="modal fade addPlanModal" tabindex="-1" role="dialog" aria-hidden="true">
	                    <div class="modal-dialog modal-lg">
	                        <div class="modal-content">
	                            <div class="modal-header">
	                                <h5 class="modal-title">Registro de nuevo plan</h5>
	                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
	                                    <span aria-hidden="true">&times;</span>
	                                </button>
	                            </div>
	                            <div class="modal-body">
	                                <b-row>
								        <b-colxx xxs="12">
								              <b-form @submit.prevent="onGridFormSubmit">
								                <b-row>									                  

								                  <b-colxx sm="6">
								                    <b-form-group :label="$t('Nombre del plan')">
								                      <b-form-input v-model="registroPlan.nombre" ></b-form-input>
								                    </b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="6">
								                    <b-form-group :label="$t('Costo')">
								                      <b-form-input v-model="registroPlan.cantidad" ></b-form-input>
								                    </b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="6">
								                    <b-form-group :label="$t('Tipo de moneda')">
								                      <b-form-select :options="objetosSelect.moneda" v-model="registroPlan.moneda" ></b-form-select>
								                    </b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="6">
								                    <b-form-group :label="$t('Cantidad de dispositivos')">
								                      <b-form-input v-model="registroPlan.numDispositivos" ></b-form-input>
								                    </b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="3">
								                    <b-form-group :label="$t('Renovar cada: ')">
								                      	<b-form-input v-model="registroPlan.repetirCada" ></b-form-input></b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="3">
								                  	<b-form-group :label="$t(' ')">
								                  		<b-form-select :options= "objetosSelect.unidadRepeticion" v-model="registroPlan.unidadRepeticion" ></b-form-select>
								                  	</b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="6">
								                    <b-form-group :label="$t('Reintentos de cobro al fallar')">
								                      <b-form-input v-model="registroPlan.reinitentosCobro" ></b-form-input>
								                    </b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="6">
								                    <b-form-group :label="$t('Estado despues de reintento')">
								                      <b-form-select :options="objetosSelect.estadoDespuesReintento" v-model="registroPlan.estadoDespuesReintento" ></b-form-select>
								                    </b-form-group>
								                  </b-colxx>

								                  <b-colxx sm="6">
								                    <b-form-group :label="$t('Días de prueba')">
								                      <b-form-input v-model="registroPlan.diasPrueba" ></b-form-input>
								                    </b-form-group>
								                  </b-colxx>

								                </b-row>

								                <button class="btn btn-outline-success" v-on:click="crearPlan();">Guardar</button>
								                <button class="btn btn-outline-danger" data-dismiss="modal" aria-label="Cerrar">Cancelar</button>
								              </b-form>
								        </b-colxx>
								    </b-row>
	                            </div>
	                        </div>
	                    </div>
	                </div>

			        <b-table
			            ref="custom-table"
			            class="vuetable"
			            sort-by="title" sort-desc.sync="false"
			            @row-selected="rowSelected"
			            selectable
			            :select-mode="bootstrapTable.selectMode"
			            :current-page="currentPage"
			            selectedVariant="primary"
			            :fields="bootstrapTable.fields"
			            :items="dataArray"
			            :per-page="perPage"
			          >
			          <template slot="status" slot-scope="data">
			            <b-badge class="mb-1" :variant="data.item.statusColor">{{ data.value }}</b-badge>
			          </template>
			        </b-table>
			        <b-pagination
			            size="sm"
			            align="center"
			            :total-rows="totalRows"
			            :per-page="perPage"
			            v-model="currentPage"
			          >
			            <template v-slot:next-text>
			              <i class="simple-icon-arrow-right"/>
			            </template>
			            <template v-slot:prev-text>
			              <i class="simple-icon-arrow-left"/>
			            </template>
			            <template v-slot:first-text>
			              <i class="simple-icon-control-start"/>
			            </template>
			            <template v-slot:last-text>
			              <i class="simple-icon-control-end"/>
			            </template>
			          </b-pagination>


			      	</b-card>
			      	<b-card :title="$t('Detalle envío')" v-if="bootstrapTable.selected.length>0">
			      		
					    <b-colxx xxs="12">
					        
				          <b-form @submit.prevent="onGridFormSubmit">
				            <b-row>

				              	<b-colxx sm="6">
				                    <b-form-group :label="$t('Nombre del plan')">
				                      <b-form-input v-model="selected.nombre" ></b-form-input>
				                    </b-form-group>
				                  </b-colxx>

				                  <b-colxx sm="6">
				                    <b-form-group :label="$t('Costo')">
				                      <b-form-input v-model="selected.cantidad" ></b-form-input>
				                    </b-form-group>
				                  </b-colxx>

				                  <b-colxx sm="6">
				                    <b-form-group :label="$t('Tipo de moneda')">
				                      <b-form-select :options="objetosSelect.moneda" v-model="selected.moneda" ></b-form-select>
				                    </b-form-group>
				                  </b-colxx>

				                  <b-colxx sm="6">
				                    <b-form-group :label="$t('Cantidad de dispositivos')">
				                      <b-form-input v-model="selected.numDispositivos" ></b-form-input>
				                    </b-form-group>
				                  </b-colxx>

				                  <b-colxx sm="6">
				                    <b-form-group :label="$t('Renovar cada: ')">
				                      	<b-form-input v-model="selected.repetirCada" ></b-form-input>
				                       	<b-form-select :options= "objetosSelect.unidadRepeticion" v-model="selected.unidadRepeticion" ></b-form-select>
				                    </b-form-group>
				                  </b-colxx>

				                  <b-colxx sm="6">
				                    <b-form-group :label="$t('Reintentos de cobro al fallar')">
				                      <b-form-input v-model="selected.reinitentosCobro" ></b-form-input>
				                    </b-form-group>
				                  </b-colxx>

				                  <b-colxx sm="6">
				                    <b-form-group :label="$t('Estado despues de reintento')">
				                      <b-form-select :options="objetosSelect.estadoDespuesReintento" v-model="selected.estadoDespuesReintento" ></b-form-select>
				                    </b-form-group>
				                  </b-colxx>

				                  <b-colxx sm="6">
				                    <b-form-group :label="$t('Días de prueba')">
				                      <b-form-input v-model="selected.diasPrueba" ></b-form-input>
				                    </b-form-group>
				                  </b-colxx>
				              
				            </b-row>

				            <!--<b-button type="button" variant="primary" class="mt-4">{{ $t('forms.signin') }}</b-button>-->

				            <b-button type="button" variant="primary" class="mt-4">Guardar cambios</b-button>


				          </b-form>
					    </b-colxx>
  
			      	</b-card>
			</b-colxx>
		</b-row>
	</div>
</template>
<script>

import { 
	mapGetters,
	mapActions
} from "vuex";

import axios from 'axios'

	export default{
		data(){
			return{
				currentPage: 1,
			    perPage: 5,
			    bootstrapTable: {
			        selected: [],
			        selectMode: 'single',
			        fields: [
			          	{ key: 'nombre', label: 'Nombre de plan', sortable: true, sortDirection: 'desc', tdClass: 'list-item-heading' },
			          	{ key: 'numDispositivos', label: 'N° dispositivos', sortable: true, tdClass: 'text-muted' },//cantidad dispositivos
			          	{ key: 'cantidad', label: 'Costo', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'moneda', label: 'Moneda', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'repetirCada', label: 'Renovar cada:', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'unidadRepeticion', label: 'Unidad medida', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'reinitentosCobro', label: 'Reintentos de cobro', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'estadoDespuesReintento', label: 'Estado despues de reintento', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'diasPrueba', label: 'Estado despues de reintento', sortable: true, tdClass: 'text-muted' },
			          	{ key: 'fechaCreacion', label: 'Fecha de creación', sortable: true, tdClass: 'text-muted', formatter: function(valueFecha){
												return new Date(valueFecha).toLocaleString("es-MX");
											}
						}
			        ]
			    },
			    dataArray: [],
			    registroPlan: {
			    	nombre: "",
			    	numDispositivos: "",
			    	cantidad: 0.00, //costo
			    	moneda: "MXN",
			    	repetirCada: 0,
			    	unidadRepeticion: "",
			    	reintentosCobro: 0,
			    	estadoDespuesReintento: 0,
			    	diasPrueba: 0
			    },
			    objetosSelect : {
			    	moneda: [
			    		{value : "MXN", text:"MXN - Peso"},
			    		{value : "USD", text:"USD - Dolar"},
			    	],
			    	unidadRepeticion: [
			    		{value : "year", text : "Años" },
			    		{value : "month", text : "Meses"},
			    		{value : "week", text : "Semanas"}
			    	],
			    	estadoDespuesReintento: [
			    		{value: "unpaid", text: "No pagado"},
			    		{value: "cancelled", text: "Cancelado"}
			    	]
			    }
			    
			}
		},

		computed: {
			totalRows(){
				return this.dataArray.length;
			},
			selected(){
				return this.bootstrapTable.selected[0];
			}
		},

		mounted: function(){
			this.dataPaginator();
		},

		methods:{
			...mapActions(["getData"]),

			dataPaginator () {
		      	//const params = this.apiParamsConverter(ctx)
		      	var instance = this;
		      	var token = JSON.parse(localStorage.getItem('user')).token;
		      	//let promise = axios.get("/api/plan");
		      	axios.request({
					url: "/api/plan",
					method: "get",
					headers: { Authorization: "Bearer " + token}
				}).then((response) => {
					instance.dataArray = response.data.data;
					instance.currentPage = 1;
				}).catch(_error => {
					instance.dataArray = [];
					instance.currentPage = 1;
					instance.totalRows = 1;
				});
		    },
		    apiParamsConverter (params) {
		      let apiParams = {}
		      if (params.perPage !== 0) {
		        apiParams.per_page = params.perPage
		      }
		      if (params.currentPage >= 1) {
		        apiParams.page = params.currentPage
		      }
		      if (params.sortBy && params.sortBy.length > 0) {
		        apiParams.sort = `${params.sortBy}|${params.sortDesc ? 'desc' : 'asc'}`
		      }
		      if (params.filter && params.filter.length > 0) {
		        // Optional
		      }
		      return apiParams
		    },
		    onPaginationData (paginationData) {
		      this.$refs.pagination.setPaginationData(paginationData)
		    },
		    onChangePage (page) {
		      this.$refs.vuetable.changePage(page)
		    },
		    rowSelected (items) {
		      this.bootstrapTable.selected = items
		    },
		    crearPlan (){
		    	var instance = this;
		    	var token = JSON.parse(localStorage.getItem('user')).token;
		    	//var deviceSessionId = OpenPay.deviceData.setup();
		    	
		    	//instance.getTokenCard(function(tokenCard){
	    		axios.request({
		    		method: "post",
		    		url:"/api/plan",
		    		headers:{ Authorization: "Bearer " + token },
		    		data:instance.registroPlan
		    	}).then(response => {
		    		console.log("RESPUESTA plan creado")
		    		console.log(response)
		    	});
		    	//});
		    }
		}
	}
</script>